<template>
  <div>
    <b-button-group
      v-for="file in existingFiles"
      :key="file['@id']"
      class="mb-1 mr-1"
      :size="small ? 'sm' : null"
    >
      <template v-if="file['@id']">
        <b-button
          v-if="isMarkedAsDeleted(file)"
          variant="danger"
          disabled
        >
          <div class="d-flex align-items-center text-left">
            <feather-icon
              v-if="!small"
              icon="FileIcon"
              class="mr-25"
              size="24"
            />
            <div>
              {{ file.originalName }} <br>
              <small>Do usunięcia</small>
            </div>
          </div>
        </b-button>
        <b-button
          v-else
          variant="light"
          @click="previewExistingFile(file)"
        >
          <div class="d-flex align-items-center text-left">
            <feather-icon
              v-if="!small"
              icon="FileIcon"
              class="mr-25"
              size="24"
            />
            <div>
              {{ file.originalName }} <br>
              <small>{{ file.updatedAt | dateTime }}</small>
            </div>
          </div>
        </b-button>
        <b-button
          v-if="upload"
          class="btn-icon"
          :variant="isMarkedAsDeleted(file) ? 'danger' : 'light'"
          @click="toggleDeleteExistingFile(file)"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
      </template>
    </b-button-group>
    <b-button-group
      v-for="(file, index) in filesToUpload"
      :key="index"
      class="mb-1 mr-1"
      :size="small ? 'sm' : null"
    >
      <b-button
        variant="light"
        @click="previewFileToUpload(file)"
      >
        <div class="d-flex align-items-center text-left">
          <feather-icon
            v-if="!small"
            icon="FileIcon"
            class="mr-25"
            size="24"
          />
          <div>
            {{ file.name }} <br>
            <small>{{ file.size | prettyBytes(2, false, "KB") }}</small>
          </div>
        </div>
      </b-button>
      <b-button
        variant="light"
        class="btn-icon"
        @click="filesToUpload.splice(index, 1)"
      >
        <feather-icon icon="Trash2Icon" />
      </b-button>
    </b-button-group>
    <b-button
      v-if="upload"
      variant="outline-primary"
      class="mb-1 h-full"
      :size="small ? 'sm' : null"
      @click="$refs.fileInput.click()"
    >
      <feather-icon icon="PlusIcon" />
      Dodaj pliki
    </b-button>
    <input
      ref="fileInput"
      type="file"
      multiple
      style="display: none"
      @input="addFilesToUpload"
    >
  </div>
</template>

<script>
import { BButton, BButtonGroup } from 'bootstrap-vue'

export default {
  components: { BButton, BButtonGroup },
  props: {
    upload: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    existingFiles: { type: Array, default: () => [] },
  },
  data() {
    return {
      filesToUpload: [],
      filesToDelete: [],
    }
  },

  methods: {
    addFilesToUpload(event) {
      this.filesToUpload = [...this.filesToUpload, ...event.target.files]
      this.$emit('input', this.filesToUpload)
    },
    previewFileToUpload(file) {
      const objectUrl = URL.createObjectURL(file)
      window.open(objectUrl)
    },
    previewExistingFile(file) {
      window.open(`${this.$http.defaults.baseURL}..${file.contentUrl}`)
    },
    toggleDeleteExistingFile(file) {
      if (!this.isMarkedAsDeleted(file)) {
        this.filesToDelete.push(file)
        this.$emit('delete', file)
      } else this.filesToDelete.splice(this.filesToDelete.indexOf(file), 1)

      this.$emit('update:filesToDelete', this.filesToDelete)
    },
    isMarkedAsDeleted(file) {
      return this.filesToDelete.includes(file)
    },
  },
}
</script>

<style>
</style>
